// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox, Select, MenuItem, InputBase, Typography, Divider, Button } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { useSelector } from 'react-redux';
import { useAppSelector, useAppDispatch } from 'Hooks';
import { selectCurrentOrder } from 'Features/order/orderSelectors';
import { SetLayer3DChangeAction, SetSelectedCadFile3DLayersAction, ToggleAll3DLayersAction } from 'Features/viewer3D/viewer3DActions';
import { Get3DLineworkThunk } from 'Features/viewer3D/viewer3DThunk';
import stylesDef from './styles';

interface CadLayersProps {
  orderId: string;
  allLayersSelected: boolean;
  toggleLayer: (checked: boolean, siteId: string, layerId: number) => void;
  toggleAllLayers: (siteId: string) => void;
  setAllLayersSelected: (allLayersSelected: boolean) => void;
}

const CadLayers = ({ orderId, allLayersSelected, toggleLayer, toggleAllLayers, setAllLayersSelected }: CadLayersProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState([]);
  const [currentfile, setCurrentFile] = useState(null);
  const [currentFile3DTileInfo, setCurrentFile3DTileInfo] = useState([]);

  const { order3DCadFilesList, cad3dLayers, selected3DCadFiles } = useAppSelector(
    (state) => state.viewer3D,
  );
  const currentOrder = useSelector((state) => selectCurrentOrder(state, orderId));
  const { cadFiles, ownerProject } = currentOrder;

  useEffect(() => {
    const currentOrderCadFileIds = order3DCadFilesList[orderId];
    if (!currentOrderCadFileIds) return;
    const fileList = currentOrderCadFileIds?.map((cadFileId: string) => {
      const cadFile = cadFiles.find((file) => file._id === cadFileId);
      const { _id, filename } = cadFile;
      const fileName = (filename?.split('/').pop() || 'Original file');
      return ({ fileName, _id });
    });
    setFiles(fileList);
    if (selected3DCadFiles[orderId]?.siteId) {
      const fileObj = fileList.find((file) => file._id === selected3DCadFiles[orderId]?.siteId);
      setCurrentFile(fileObj);
    } else {
      setCurrentFile(fileList[fileList.length - 1]);
    }
  }, []);

  useEffect(() => {
    if (currentfile) {
      setCurrentFile3DTileInfo(cad3dLayers[currentfile._id]?.layers);
    }
  }, [currentfile, cad3dLayers]);

  const onFileChange = async (e: any) => {
    const siteId = e.target.value;
    const file = files.find((f) => f._id === siteId);
    const currentSiteId = currentfile?._id;
    setCurrentFile(file);

    dispatch(SetSelectedCadFile3DLayersAction({
      orderId,
      cadFile3DLayers: {
        siteId,
        visible: true,
      },
    }));
    const site = cad3dLayers[siteId];
    if (site) {
      const { dataAvailable, layers } = site;
      const layerIds = layers.map((l) => l.layer_id);
      if (!dataAvailable) {
        await dispatch(Get3DLineworkThunk([{ projectId: ownerProject, siteId, layers: layerIds }]));
      }
      const layerChange: (string|boolean)[] = [];
      cad3dLayers[currentSiteId]?.layers.forEach((layer) => {
        layerChange.push(`${currentSiteId}-${layer.layer_id}`, false && layer.visible);
      });
      cad3dLayers[siteId]?.layers.forEach((layer) => {
        dispatch(ToggleAll3DLayersAction({ siteId, toggle: true }));
        setAllLayersSelected(true);
        layerChange.push(`${siteId}-${layer.layer_id}`, true && layer.visible);
      });
      dispatch(SetLayer3DChangeAction(layerChange));
    }
  };

  return (
    <Box sx={styles.layerList}>
      <Select
        input={<InputBase />}
        value={currentfile?._id || ''}
        renderValue={() => <>{currentfile?.fileName}</>}
        onChange={(e) => onFileChange(e)}
        sx={styles.select}
      >
        {files?.map((file) => (
          <MenuItem value={file?._id} key={file?._id}>{file?.fileName}</MenuItem>
        ))}
      </Select>
      <Divider sx={styles.divider} />
      <Button variant="text" sx={styles.layersButton} onClick={() => toggleAllLayers(currentfile?._id)}>
        {allLayersSelected ? <span>Deselect All</span> : <span>Select All</span>}
      </Button>
      <Box sx={styles.listWrapper}>
        {currentFile3DTileInfo?.map((layer: any) => (
          <Box sx={styles.layerListItem} key={layer.layer_id}>
            <Box sx={styles.checkboxWrapper}>
              <FormControlLabel
                label={<Typography variant="h5" sx={styles.sidebarSubheader}>{layer.name}</Typography>}
                control={(
                  <Checkbox
                    color="primary"
                    onChange={(e) => toggleLayer(e.target.checked, currentfile?._id, layer.layer_id)}
                    checked={layer.visible}
                    sx={styles.checkbox}
                  />
                )}
              />
            </Box>
            <Box
              sx={{ ...styles.layerColor, backgroundColor: layer.color }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

CadLayers.propTypes = {
  orderId: PropTypes.string.isRequired,
  allLayersSelected: PropTypes.bool.isRequired,
  toggleLayer: PropTypes.func.isRequired,
  toggleAllLayers: PropTypes.func.isRequired,
  setAllLayersSelected: PropTypes.func.isRequired,
};

export default CadLayers;
