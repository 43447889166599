// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  checkBoxTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
  },
  title: {
    color: '#788898',
    textTransform: 'uppercase',
  },
  orderContainer: {
    marginTop: '10px',
    marginLeft: '5px',
    paddingLeft: '10px',
    borderLeft: '2px solid #B0B8C4',
  },
  layers: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
  },
  layerName: {
    color: '#021E40',
  },
  lasfileContainer: {
    marginLeft: theme.spacing(1),
  },
  lasfile: {
    fontWeight: 400,
    color: '#021E40',
    padding: '8px 0 4px 8px',
    borderLeft: '3px solid #B0B8C4',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  layerGroup: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  flexItem: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '4px',
  },
  icon: {
    color: '#a1adba',
    cursor: 'pointer',
  },
  lasFileListItem: {
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '12px',
    width: '100%',
    gap: '10%',
    justifyContent: 'space-between',
  },
  pointCloudSelection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dot: {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    display: 'inline-block',
    margin: '0 4px',
  },
  classContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  classname: {
    fontSize: 14,
    fontWeight: 300,
    padding: '8px',
  },
  classesContainer: {
    padding: '0 10px',
  },
  layersExpandButton: {
    padding: '6px',
  },
});

export default styles;
