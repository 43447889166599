// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  paddingBottom24: {
    paddingBottom: theme.spacing(3),
  },
  paddingBottom16: {
    paddingBottom: theme.spacing(2),
  },
  paddingTop40: {
    paddingTop: theme.spacing(5),
  },
  paddingTop16: {
    paddingTop: theme.spacing(2),
  },
  paddingTop8: {
    paddingTop: theme.spacing(1),
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: 400,
    wordBreak: 'break-word',
    fontSize: '22px',
    lineHeight: '28px',
    paddingRight: '20px',
  },
  summaryText: {
    textTransform: 'capitalize',
    fontSize: '20px',
    marginTop: theme.spacing(3),
  },
  mapColumn: {
    top: theme.spacing(10),
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
  mapColumnSuccess: {
    border: 'none',
    marginTop: theme.spacing(2),
    backgroundColor: '#4f5a6414',
  },
  orderInfo: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
  map: {
    height: 130,
    width: 150,
  },
  cost: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  total: {
    textAlign: 'right',
  },
  submitButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  continueButton: {
    boxShadow: 'none',
    marginTop: theme.spacing(2),
    width: 240,
    height: 48,
    marginLeft: 'auto',
  },
  pdfButton: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    width: 240,
    height: 48,
    backgroundColor: '#E5EAEE',
    color: '#021E40',
  },
  saveButton: {
    boxShadow: 'none',
    marginTop: theme.spacing(2),
    width: 200,
    height: 55,
    borderColor: '#2E4DEB',
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      opacity: 0.5,
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  downloadText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    fontSize: '14px',
  },
  downloadContainer: {
    paddingRight: '10px',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
  },
  topoControls: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr 1fr auto 1fr 1fr',
    alignItems: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: 'contents',
    marginLeft: '0px',
  },
  dropdown: {
    textTransform: 'lowercase',
    background: '#FAFAFC',
    paddingLeft: '10px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      color: theme.palette.text.disabled,
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.05)',
    },
  },
  defaultText: {
    float: 'right',
    color: '#2e4deb',
    fontSize: 'smaller',
  },
  innerDropdownText: {
    display: 'contents',
    textTransform: 'lowercase',
  },
  menuItem: {
    justifyContent: 'space-between',
  },
  cadText: {
    paddingBottom: '10px',
  },
  topoTitle: {
    marginTop: theme.spacing(2),
    paddingBottom: '10px',
  },
  topoText: {
    textTransform: 'capitalize',
    marginRight: theme.spacing(1),
  },
  ellipsedText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  creditsText: {
    width: '200px',
  },
  topoTextSuccess: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridColumnGap: theme.spacing(3),
  },
  ftText: {
    textTransform: 'lowercase',
    color: '#788898',
    marginLeft: theme.spacing(1),
  },
  deliveryDateText: {
    color: '#01A1DD',
    textTransform: 'none',
    fontSize: '16px',
    letter: '0.21px',
    lineHeight: '19px',
  },
  rightContent: {
    position: 'absolute',
    right: 0,
  },
  blueFont: {
    color: '#01A1DD',
  },
  dateGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gridColumnGap: theme.spacing(1),
  },
  divGrid: {
    display: 'grid',
    position: 'relative',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  extras: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  extrasText: {
    fontSize: '12px',
    fontWeight: 400,
  },
  extrasCost: {
    position: 'absolute',
    right: 0,
    top: 0,
    fontSize: '12px',
    fontWeight: 400,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  warningIcon: {
    color: '#F95264',
  },
  dialog: {
    minWidth: '600px',
    margin: 'auto',
  },
  dialogBox: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    boxShadow: '0 0 4px #ccc',
    marginTop: theme.spacing(3),
  },
  dialogTitle: {
    display: 'grid',
    gridTemplateColumns: 'auto auto 1fr',
    alignItems: 'center',
    padding: '10px 0',
  },
  okButton: {
    color: '#01a1dd',
    marginLeft: 'none',
    '&:hover': {
      backgroundColor: 'white !important',
    },
  },
  titleText: {
    paddingLeft: theme.spacing(1),
  },
  subSectionTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  deliverableList: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    justifySelf: 'left',
    '&>*:nth-child(2n)': {
      textAlign: 'right',
      justifySelf: 'right',
    },
  },
  checkbox: {
    '&.Mui-disabled': {
      cursor: 'not-allowed',
    },
  },
  processingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
  },
  checkboxLabel: {
    fontSize: '14px',
  },
  expeditedDelivery: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '13px',
    marginTop: theme.spacing(1),
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: '#2e4deb',
    cursor: 'pointer',
  },
  icon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    cursor: 'pointer',
  },
  density: {
    marginBottom: theme.spacing(2),
  },
  densityTitle: {
    margin: `${theme.spacing(2)}px 0`,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  densityInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  densityText: {
    textTransform: 'capitalize',
  },
  disabled: {
    color: theme.palette.text.disabled,
    cursor: 'not-allowed',
  },
  orderDetail: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  orderDetailTitle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.7,
  },
  orderDetailValue: {
    position: 'absolute',
    right: 0,
    top: 0,
    fontSize: '14px',
    fontWeight: 400,
  },
  crs: {
    fontWeight: 300,
  },
  notes: {
    fontSize: '13px',
    fontWeight: 400,
    color: '#bdbdbd',
    marginTop: theme.spacing(1),
  },
  buttonText: {
    position: 'absolute',
    fontSize: '11px',
    textAlign: 'center',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    width: '200px',
    padding: theme.spacing(2),
  },
});

export default styles;
