// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { FormControl, RadioGroup, Radio, FormControlLabel, Typography, Select, MenuItem } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { ResetBundleStateAction, SetDensityAction } from 'Features/bundle/bundleActions';
import { GetBundlesThunk, SetDeliverablesThunk } from 'Features/bundle/bundleThunk';
import { useSelector } from 'react-redux';
import { getOrders } from 'Features/order/orderSelectors';
import { RootState } from 'Store';
import { utilitiesPackagesSubtypes } from 'Utils/constants';
import RadioButtonLabel from './RadioButtonLabel';
import IncludedAddOns from './IncludedAddOns';
import stylesDef from './styles';

interface BundleSelectProps {
  bundleType: string;
  bundleSubtype: string;
  deliverySpeed: string;
  pricePerFoot: number;
  setPricePerFoot: (arg0: number) => void;
  setBundleSubtype: (arg0: string) => void;
  checkFirstTimeChangeEstimate: (arg0: Function) => void;
  zeroAddOns: () => void;
}

const BundleSelect = ({ bundleType, bundleSubtype, deliverySpeed, setBundleSubtype, checkFirstTimeChangeEstimate, zeroAddOns, pricePerFoot, setPricePerFoot}: BundleSelectProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState('');
  const { availableBundles, selectedBundle, density, list: bundles } = useAppSelector(
    (state) => state.bundle,
  );
  const { placedOrder, activeOrder } = useSelector((state: RootState) => getOrders(state));
  const { project, rasterizationSelected } = useAppSelector(
    (state: RootState) => state.project,
  );

  const selectBundle = (name: string) => {
    let findBundles;
    if (name === 'Topo') {
      findBundles = bundles.find((b) => b.name === name && b.deliverySpeed === deliverySpeed);
      dispatch(SetDensityAction('normal'));
    } else {
      findBundles = bundles.find((b) => b.name === name && b.density === density && b.deliverySpeed === deliverySpeed);
    }

    dispatch(SetDeliverablesThunk(findBundles, false));
  };

  useEffect(() => {
    setValue(selectedBundle?.name);
  }, [selectedBundle]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const bundleName = (event.target as HTMLInputElement).value;
    checkFirstTimeChangeEstimate(() => {
      zeroAddOns();
      selectBundle(bundleName);
    });
  };

  const handleSubtypeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSubtype = event.target.value;
    zeroAddOns();
    dispatch(ResetBundleStateAction());
    setBundleSubtype(newSubtype);
    const { isEstimate } = activeOrder;
    await dispatch(GetBundlesThunk(activeOrder._id, project._id, bundleType, newSubtype, isEstimate));
  };

  return (
    <Box sx={styles.bundleContainer}>
      {bundleType === 'utilities' && (
        <>
          <Typography>Choose a bundle:</Typography>
          <FormControl fullWidth>
            <Select
              value={bundleSubtype}
              onChange={handleSubtypeChange}
            >
              {utilitiesPackagesSubtypes.map((subtype) => (<MenuItem value={subtype}>{subtype}</MenuItem>))}
            </Select>
          </FormControl>
        </>
      )}
      <Box sx={styles.bundleContainer}>
        <FormControl sx={styles.bundleOption}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={value}
            onChange={handleChange}
            sx={styles.bundleOption}
          >
            {availableBundles?.map((bundle: IBaseBundle) => (
              <Box
                key={bundle._id}
                sx={{ ...styles.bundleOption, ...(bundle.name === selectedBundle?.name && styles.selectedBundle) }}
              >
                <FormControlLabel
                  sx={styles.bundleOption}
                  value={bundle.name}
                  control={<Radio />}
                  label={<RadioButtonLabel bundle={bundle} bundleType={bundleType} pricePerFoot={pricePerFoot} setPricePerFoot={setPricePerFoot} selectedBundle={selectedBundle}
/>}
                  disabled={
                    (bundle.bundleId === 9 && placedOrder && !project?.qcLevel)
                    || (bundle.bundleId !== 9 && placedOrder && project?.qcLevel)
                    || (bundle.bundleId === 9 && (project?.imageryRequest || rasterizationSelected))
                  }
                />
              </Box>
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <IncludedAddOns />
    </Box>
  );
};

export default BundleSelect;
