// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography, FormControl, MenuItem, Select, InputBase } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { SetTopoMajrAction, SetTopoMinrAction } from 'Features/bundle/bundleActions';
import { topoIntervals } from 'Utils/constants';
import { WideLightTooltip } from 'Components/Tooltip';
import { SetDeliverablesThunk } from 'Features/bundle/bundleThunk';
import stylesDef from './styles';

interface TopoSelectProps {
  disableDensity: boolean;
  deliverySpeed: string;
  checkFirstTimeChangeEstimate: (arg0: Function) => void;
}

const TopoSelect = ({ disableDensity, deliverySpeed, checkFirstTimeChangeEstimate }: TopoSelectProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const { placeOrderSuccess } = useAppSelector(
    (state) => state.order,
  );

  const { topoMajr, topoMinr, density, densityOptions, list: bundles, selectedBundle } = useAppSelector(
    (state) => state.bundle,
  );

  const handleDensity = (d: string) => {
    const findBundles = bundles.find((b) => b.name === selectedBundle?.name && b.density === d && b.deliverySpeed === deliverySpeed);
    dispatch(SetDeliverablesThunk(findBundles, true));
  };

  return (placeOrderSuccess ? (
    <>
      {(topoMajr || topoMinr) && <Typography variant="h6" sx={styles.topoTitle}>TOPOGRAPHY INTERVAL</Typography>}
      <Box sx={styles.topoTextSuccess}>
        {topoMajr && <Typography variant="body1">{`Major: ${topoMajr} ft `}</Typography>}
        {topoMinr && <Typography variant="body1">{`Minor: ${topoMinr} ft `}</Typography>}
      </Box>
    </>
  ) : (
    <>
      <Typography variant="h6" sx={styles.topoTitle}>TOPOGRAPHY INTERVAL</Typography>
      <Box sx={styles.topoControls}>
        <Typography variant="h6" sx={styles.topoText}>
          Major:
        </Typography>
        <FormControl sx={styles.formControl}>
          <Select
            value={topoMajr ?? ''}
            input={<InputBase />}
            onChange={(e: any) => checkFirstTimeChangeEstimate(() => dispatch(SetTopoMajrAction(e.target.value)))}
            variant="outlined"
            sx={styles.dropdown}
          >
            {topoIntervals.map((topo: any) => (
              <MenuItem key={topo} value={topo} sx={styles.menuItem}>
                <Typography variant="h4" sx={styles.innerDropdownText}>
                  {topo}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="h6" sx={styles.ftText}>ft</Typography>
        <Typography variant="h6" sx={styles.topoText}>
          Minor:
        </Typography>
        <FormControl sx={styles.formControl}>
          <Select
            value={topoMinr ?? ''}
            input={<InputBase />}
            onChange={(e: any) => checkFirstTimeChangeEstimate(() => dispatch(SetTopoMinrAction(e.target.value)))}
            variant="outlined"
            sx={styles.dropdown}
          >
            {topoIntervals.map((topo: any) => (
              <MenuItem key={topo} value={topo} sx={styles.menuItem}>
                <Typography variant="h4" sx={styles.innerDropdownText}>
                  {topo}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="h6" sx={styles.ftText}>ft</Typography>
      </Box>
      <Box sx={styles.density}>
        <Box sx={styles.dateGrid}>
          <Typography variant="h6" sx={{ ...styles.densityTitle, ...(disableDensity && styles.disabled) }} onClick={() => window.open('http://support.airworks.io/Normal-Vs.-Low-Density.2351923205.html', '_tab')}>PROJECT DENSITY</Typography>
          <WideLightTooltip
            placement="bottom"
            disableHoverListener={disableDensity}
            title={(
              <Box>
                Density change is subject to final approval by AirWorks. We will notify you within 1 business day if we modify your density estimation. You will be able to view or, if necessary, cancel your order from "Projects".
              </Box>
            )}
          >
            <Box sx={styles.densityInfo}>
              <InfoIcon sx={{ ...styles.icon, ...(disableDensity && styles.disabled) }} />
            </Box>
          </WideLightTooltip>
        </Box>
        <FormControl sx={styles.formControl} disabled={disableDensity}>
          {disableDensity ? (
            <Select
              value="Topo"
              input={<InputBase style={{ width: '100%' }} />}
              sx={styles.dropdown}
            >
              <MenuItem value="Topo" sx={styles.menuItem}>
                <Typography variant="h4" color="inherit" sx={{ ...styles.innerDropdownText, ...styles.disabled }}>
                  No density can be set
                </Typography>
              </MenuItem>
            </Select>
          ) : (
            <Select
              value={density}
              input={<InputBase style={{ width: '100%' }} />}
              onChange={(e: any) => checkFirstTimeChangeEstimate(() => handleDensity(e.target.value))}
              sx={styles.dropdown}
            >
              {densityOptions.map((dens) => (
                <MenuItem key={dens} value={dens} sx={styles.menuItem}>
                  <Typography variant="h4" sx={{ ...styles.innerDropdownText, ...styles.densityText }}>
                    {dens}
                  </Typography>
                  {dens === 'normal' && (
                    <Typography variant="h4" sx={styles.defaultText}>
                      Default
                    </Typography>
                  )}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </Box>
    </>
  )
  );
};

export default TopoSelect;
