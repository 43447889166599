// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { styled, alpha, Box } from '@mui/system';
import { Slider as BaseSlider, sliderClasses } from '@mui/base/Slider';

interface StyledSliderProps {
    width: number;
    min: number;
    max: number;
    value: number;
    onChange: (event: Event) => void;
}

const StyledSlider = ({ width, min, max, value, onChange }: StyledSliderProps) => (
  <Box sx={{ width }}>
    <Slider min={min} max={max} value={value} onChange={onChange} />
  </Box>
);

export default StyledSlider;

const Slider = styled(BaseSlider)(
  () => `
  color: #007FFF;
  height: 4px;
  width: 100%;
  padding: 16px 0;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  & .${sliderClasses.rail} {
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 6px;
    background-color: currentColor;
    opacity: 0.3;
  }

  & .${sliderClasses.track} {
    display: block;
    position: absolute;
    height: 4px;
    border-radius: 6px;
    background-color: currentColor;
  }

  & .${sliderClasses.thumb} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: -6px;
    width: 15px;
    height: 15px;
    box-sizing: border-box;
    border-radius: 50%;
    outline: 0;
    background-color: #007FFF;
    transition-property: box-shadow, transform;
    transition-timing-function: ease;
    transition-duration: 120ms;
    transform-origin: center;

    &:hover {
      box-shadow: 0 0 0 6px ${alpha('#99CCF3', 0.3)};
    }

    &.${sliderClasses.focusVisible} {
      box-shadow: 0 0 0 8px ${alpha('#99CCF3', 0.5)};
      outline: none;
    }

    &.${sliderClasses.active} {
      box-shadow: 0 0 0 8px ${alpha('#99CCF3', 0.5)};
      outline: none;
      transform: scale(1.2);
    }
  }
`,
);
