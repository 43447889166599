// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme: any) => ({
  container: {
    padding: '12px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 4px 8px rgb(0 0 0 / 0.6)',
    borderRadius: '3px',
  },
  text: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default styles;
