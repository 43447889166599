// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, useTheme } from '@mui/system';
import { TransitionProps } from '@mui/material/transitions/transition';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Dialog, Slide, IconButton, Typography, InputBase, Button, Divider, Fade, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { RootState } from 'Store';
import { useSelector } from 'react-redux';
import { getProjectEPSGCode, selectProjectFiles } from 'Features/project/projectSelectors';
import { GetProjectEpsgCodeThunk } from 'Features/project/projectThunk';
import { ResetEpsgProjectionsAction } from 'Features/project/projectActions';
import UploadFilesDialog from 'Components/UploadFilesDialog';
import ErrorDialog from 'Components/ErrorDialog';
import { selectAvailableTeams } from 'Features/account/accountSelectors';
import { SubmitCustomOrderThunk } from '../orderThunk';
import { SetPlaceOrderSuccessAction } from '../orderActions';
import { getOrders } from '../orderSelectors';
import EditOrder from '../OrderForm/ConfirmColumn/EditOrder';
import DetailsColumn from './DetailsColumn';
import stylesDef from './styles';

interface CustomOrderDialogProps {
  orderFormVisible: boolean;
  setOrderFormVisible: (visible: boolean) => void;
}

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide ref={ref} direction="up" {...props} />
));

const CustomOrderDialog = ({ orderFormVisible, setOrderFormVisible }: CustomOrderDialogProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [summaryText, setSummaryText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileErrorsExist, setFileErrorsExist] = useState(false);
  const [showStillProcessingDialog, setShowStillProcessingDialog] = useState(false);
  const [showEpsgMismatchDialog, setShowEpsgMismatchDialog] = useState(false);
  const [epsgMismatchAcknowledged, setEpsgMismatchAcknowledged] = useState(false);
  const [showErrorsDialog, setShowErrorsDialog] = useState(false);
  const [filesMissing, setFilesMissing] = useState(false);
  const [showFilesMissingDialog, setShowFilesMissingDialog] = useState(false);
  const [showTifRequiredDialog, setShowTifRequiredDialog] = useState(false);
  const [uploadFilesFormVisible, setUploadFilesFormVisible] = useState(false);
  const [reviewChecklist, setReviewChecklist] = useState(false);
  const { customCheckoutOptionalRasterization } = useFlags();

  const { project, fileStatusJson } = useAppSelector(
    (state: RootState) => state.project,
  );
  const { rasterizationSelected } = useAppSelector((state: RootState) => state.project);
  const { placeOrderSuccess } = useAppSelector(
    (state) => state.order,
  );
  const { imageryRequest } = project || {};
  const { activeOrder, placedOrder } = useSelector((state: RootState) => getOrders(state));
  const files = useSelector((state: RootState) => selectProjectFiles(state));
  const { tifEpsg, lasEpsg, epsgMatch } = useSelector((state: RootState) => getProjectEPSGCode(state));
  const { availableTeams } = useSelector((state: RootState) => selectAvailableTeams(state));

  const currentTeam = availableTeams?.find((t) => t._id === activeOrder?.team);
  const { customKbUrl } = useFlags();
  const kbLink: string = customKbUrl.url || null;
  // when rasterization is selected, tif file is not required to submit using the custom order form
  const tifRequired: boolean = !rasterizationSelected && !!(customKbUrl?.pipeline);

  const tifMissing = !project?.tifFiles || project?.tifFiles.length === 0;
  const lasMissing = !project?.lasFiles || project?.lasFiles.length === 0;

  const verifyFiles = async () => {
    if (imageryRequest) {
      setPage(page + 1);
      return;
    }

    if (tifMissing && lasMissing) {
      setShowFilesMissingDialog(true);
      setFilesMissing(true);
      return;
    }

    // custom checkout flag is enabled to allow accounts to automatically request rasterization without selecting a checkbox.
    if (tifMissing && tifRequired && customCheckoutOptionalRasterization && (Object.keys(customCheckoutOptionalRasterization).length > 0 && !customCheckoutOptionalRasterization.raster)) {
      setShowTifRequiredDialog(true);
      return;
    }

    let errors = fileErrorsExist;

    files.forEach((file) => {
      if (fileStatusJson && fileStatusJson[file.name] && fileStatusJson[file.name].status === 'ERROR') {
        errors = errors || true;
      }
    });

    if (errors) {
      setShowErrorsDialog(true);
      setFileErrorsExist(true);
      return;
    }

    if ((!tifMissing && !tifEpsg) || (!lasMissing && !lasEpsg)) {
      const { tifWithEpsg, lasWithEpsg } = await dispatch(GetProjectEpsgCodeThunk(project._id));

      if (!tifWithEpsg) {
        setShowStillProcessingDialog(true);
        return;
      }
      if (!lasWithEpsg) {
        setShowStillProcessingDialog(true);
        return;
      }
    }

    if ((!tifMissing && tifEpsg) && (!lasMissing && lasEpsg) && !epsgMatch && !epsgMismatchAcknowledged) {
      setShowEpsgMismatchDialog(true);
      return;
    }

    if (!filesMissing && !fileErrorsExist) {
      setPage(page + 1);
    }
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setFilesMissing(false);
    setFileErrorsExist(false);
    setShowFilesMissingDialog(false);
    setShowTifRequiredDialog(false);
    setShowErrorsDialog(false);
    e.stopPropagation();
  };

  const onCustomSubmit = async () => {
    setIsSubmitting(true);
    const result = await dispatch(SubmitCustomOrderThunk(summaryText));
    setIsSubmitting(false);
    if (result.success) {
      window?.pendo?.track('Custom Bundle Checkout', {
        projectId: project?._id,
        textEntry: summaryText,
        kmlSize: placedOrder?.acreage.toFixed(2),
        filesUploaded: files?.map((file) => file.name).join(', '),
      });
      setOrderFormVisible(false);
      resetFormState();
      SetPlaceOrderSuccessAction(false);
      navigate('/projects');
    }
  };

  const resetFormState = () => {
    setPage(0);
    setSummaryText('');
    setReviewChecklist(false);
  };

  const onClose = () => {
    SetPlaceOrderSuccessAction(false);
    setOrderFormVisible(false);
    resetFormState();
    if (placeOrderSuccess) {
      navigate('/projects');
    }
  };

  useEffect(() => {
    if (tifMissing && lasMissing) {
      dispatch(ResetEpsgProjectionsAction());
    }
  }, [tifMissing, lasMissing]);

  return (
    <Dialog fullScreen open={orderFormVisible} TransitionComponent={Transition}>
      <Box sx={styles.pageControls}>
        <Box sx={styles.pageControlsSub}>
          <Box sx={styles.backButton}>
            {page > 0 && (
              <IconButton color="inherit" aria-label="Back" onClick={() => setPage(0)}>
                <KeyboardBackspaceIcon fontSize="large" />
              </IconButton>
            )}
          </Box>
          <Box sx={styles.tabs}>
            <>
              <Typography
                variant="body1"
                sx={{ ...(page === 0 && styles.activeTabName) }}
              >
                1. Order Summary
              </Typography>
              <ChevronRightIcon sx={styles.chevron} />
              <Typography
                variant="body1"
                sx={{ ...(page === 1 && styles.activeTabName) }}
              >
                2. Confirm and Process
              </Typography>
            </>
          </Box>
          <Box sx={styles.closeButtonWrapper}>
            <IconButton color="inherit" onClick={onClose} aria-label="Close">
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.pageWrapper}>
        {page === 0 && (
          <Box sx={styles.summaryColumn}>
            <Typography variant="h1">Order Summary</Typography>
            <Typography variant="h6" sx={styles.subtitleText}>Please enter your order summary here</Typography>
            <Box sx={styles.textField}>
              <InputBase
                sx={styles.inputText}
                multiline
                fullWidth
                value={summaryText}
                onChange={(e) => setSummaryText(e.target.value)}
                placeholder="Example: Summary A, Summary B..."
              />
            </Box>
            <Button sx={styles.button} variant="contained" color="primary" onClick={verifyFiles}>Next</Button>
          </Box>
        )}
        {page === 1 && (
          <Box sx={styles.summaryColumn}>
            <Typography variant="h1" sx={styles.confirmHeader}>Confirm and Process</Typography>
            {summaryText && (
              <>
                <Typography variant="h3" sx={styles.subHeader}>Order Summary</Typography>
                <Divider sx={styles.divider} />
                <Typography variant="body1" sx={styles.summaryText}>{`${summaryText}`}</Typography>
              </>
            )}
            {kbLink && (
              <FormControlLabel
                sx={styles.checkBox}
                control={(
                  <Checkbox
                    checked={reviewChecklist}
                    onChange={() => setReviewChecklist(!reviewChecklist)}
                    name="reviewChecklist"
                    color="primary"
                  />
                )}
                label={(
                  <Typography variant="body1" sx={styles.checkBoxText}>
                    I acknowledge I have reviewed the
                    {' '}
                    <a style={styles.link} href={kbLink} target="_blank" rel="noreferrer">project quality checklist</a>
                    {' '}
                    for this order.
                  </Typography>
                )}
              />
            )}
            <EditOrder />
            <Divider sx={styles.editOrderDivider} />
            <Box sx={styles.orderInfo}>
              <Typography variant="body1">Team</Typography>
              <Typography variant="body1">{currentTeam?.name || 'N/A'}</Typography>
              <Typography variant="body1">Reference Id</Typography>
              <Typography variant="body1">{activeOrder?.referenceId || 'N/A'}</Typography>
            </Box>
            <Button variant="contained" color="primary" sx={styles.submitButton} onClick={onCustomSubmit} disabled={isSubmitting || (!reviewChecklist && !!(kbLink))}>
              <Fade in={isSubmitting}>
                <CircularProgress size={24} sx={styles.buttonProgress} />
              </Fade>
              <Fade in={!isSubmitting}>
                <span>{imageryRequest ? 'Request Imagery' : 'Submit Order'}</span>
              </Fade>
            </Button>
          </Box>
        )}
        <Box>
          <DetailsColumn />
        </Box>
      </Box>
      <ErrorDialog
        showDialog={showFilesMissingDialog}
        onAccept={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setShowFilesMissingDialog(false);
          setUploadFilesFormVisible(true);
          e.stopPropagation();
        }}
        onCancel={onCancel}
        titleText="Your order requires data to be uploaded to proceed with processing"
        acceptText="Upload files"
        cancelText="OKAY"
        error
      >
        <Typography variant="body1" sx={styles.errorMessage}>
          An orthomosaic(.tif) or a point cloud(.las or.laz) is required to process this order
        </Typography>
      </ErrorDialog>
      <ErrorDialog
        showDialog={showTifRequiredDialog}
        onAccept={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setShowTifRequiredDialog(false);
          setUploadFilesFormVisible(true);
          e.stopPropagation();
        }}
        onCancel={onCancel}
        titleText="Your order requires a tif file to proceed with processing"
        acceptText="Upload files"
        cancelText="OKAY"
        error
      >
        <Typography variant="body1" sx={styles.errorMessage}>
          Please upload an orthomosaic(.tif) to process this order
        </Typography>
      </ErrorDialog>
      <ErrorDialog
        showDialog={showErrorsDialog}
        onAccept={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setShowErrorsDialog(false);
          setUploadFilesFormVisible(true);
          e.stopPropagation();
        }}
        onCancel={onCancel}
        titleText="Your files have errors that require resolution to proceed with processing."
        error
        acceptText="Yes, upload now"
        cancelText="Upload Later"
      >
        <Box sx={styles.errorMessage}>
          {fileStatusJson && Object.keys(fileStatusJson).length > 0 && (
            Object.keys(fileStatusJson).map((file) => (
              (fileStatusJson[file].status === 'ERROR') ? (
                <Box key={file}>
                  <Typography variant="body1" sx={styles.errorText}>
                    The following errors were found in
                    <Typography sx={styles.errorFile}>{` ${file}:`}</Typography>
                  </Typography>
                  {fileStatusJson[file].messages.map((message) => (
                    <Typography variant="body1" sx={styles.errorItem} key={message}>
                      {`${message}`}
                    </Typography>
                  ))}
                </Box>
              ) : null))
          )}
          <Typography variant="h3">Would you like to resolve errors and re-upload files now?</Typography>
        </Box>
      </ErrorDialog>
      <ErrorDialog
        showDialog={showStillProcessingDialog}
        onCancel={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setShowStillProcessingDialog(false);
          e.stopPropagation();
        }}
        titleText="Still validating your upload"
        cancelText="OKAY"
        error
      >
        <Typography variant="body1" sx={styles.errorMessage}>
          We’re not yet able to detect a valid
          <span style={styles.bold}> EPSG </span>
          code for your file. We’ll share a detailed error message if we have one.
        </Typography>
      </ErrorDialog>
      <ErrorDialog
        showDialog={showEpsgMismatchDialog}
        onCancel={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setShowEpsgMismatchDialog(false);
          setEpsgMismatchAcknowledged(true);
          e.stopPropagation();
        }}
        onAccept={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setShowEpsgMismatchDialog(false);
          setUploadFilesFormVisible(true);
          e.stopPropagation();
        }}
        titleText="EPSG Code Discrepancy"
        acceptText="Upload files"
        cancelText="Ignore, Proceed to Project"
        error={false}
      >
        <>
          {files.map((file) => (
            <Typography variant="body1" key={file._id} sx={styles.filesList}>{`${file.name} - EPSG: ${file.epsg}`}</Typography>
          ))}
          <Typography variant="body1" sx={styles.epsgErrorMessage}>
            We have detected conflicting EPSG codes from the files you have uploaded.
            We strongly recommend uploading your input data in the same CRS to avoid potential delays or quality issues in your final deliverable.
            {' '}
            <a style={styles.link} href="https://awkb.scrollhelp.site/airworksknowledgebase/coordinate-reference-system-crs" target="_blank" rel="noreferrer">Click here to learn more</a>
          </Typography>
        </>
      </ErrorDialog>
      <UploadFilesDialog
        showDialog={uploadFilesFormVisible}
        setShowDialog={setUploadFilesFormVisible}
        orderFormVisible={orderFormVisible}
        setOrderFormVisible={setOrderFormVisible}
      />
    </Dialog>
  );
};

export default CustomOrderDialog;
