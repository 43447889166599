// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Box, useTheme } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup';
import StyledSlider from 'Components/Slider';
import { defaultDxfPtSize, defaultLazPtSize } from 'Utils/constants';
import stylesDef from './styles';

interface PointSizeToolProps {
    anchor: HTMLElement;
    inputPtSize: number;
    setInputPtSize: (value: number) => void;
    outputPtSize: number;
    setOutputPtSize: (value: number) => void;
    dxfPtSize: number;
    setDxfPtSize: (value: number) => void;
    has3dPoint: boolean;
    hasOutputPt: boolean;
}

const PointSizeTool = ({ anchor, inputPtSize, setInputPtSize, outputPtSize, setOutputPtSize, dxfPtSize, setDxfPtSize, has3dPoint, hasOutputPt }: PointSizeToolProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);

  const handleReset = () => {
    setInputPtSize(defaultLazPtSize);
    setOutputPtSize(defaultLazPtSize);
    setDxfPtSize(defaultDxfPtSize);
  };

  return (
    <Popup open={Boolean(anchor)} placement="bottom-start" anchor={anchor}>
      <Box sx={styles.container}>
        <Box sx={styles.text}>
          <Typography variant="body2">Input Point Cloud</Typography>
          <Typography variant="body2">{inputPtSize}</Typography>
        </Box>
        <Box>
          <StyledSlider width={250} min={1} max={10} value={inputPtSize} onChange={(event) => setInputPtSize(event.target.value)} />
        </Box>
        {!!has3dPoint && (
          <>
            <Box sx={styles.text}>
              <Typography variant="body2">3D Points on CAD</Typography>
              <Typography variant="body2">{dxfPtSize}</Typography>
            </Box>
            <Box>
              <StyledSlider width={250} min={1} max={20} value={dxfPtSize} onChange={(event) => setDxfPtSize(event.target.value)} />
            </Box>
          </>
        )}
        {!!hasOutputPt && (
          <>
            <Box sx={styles.text}>
              <Typography variant="body2">Classified Point Cloud</Typography>
              <Typography variant="body2">{outputPtSize}</Typography>
            </Box>
            <Box>
              <StyledSlider width={250} min={1} max={10} value={outputPtSize} onChange={(event) => setOutputPtSize(event.target.value)} />
            </Box>
          </>
        )}
        <Button onClick={handleReset}>RESET</Button>
      </Box>
    </Popup>
  );
};

export default PointSizeTool;
