// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { ActionCreators } from 'redux-undo';
import { datadogRum } from '@datadog/browser-rum';
import { getJson, postJson, deleteRequest, patchJson } from 'Utils/http';
import { wktToCurve, curveToWkt } from 'WktCurves';
import GenerateGuid from 'Utils/guid';
import { API_URL } from 'Config';
import type { RootState } from 'Store';
import { AddNewLayerToVTJ, UpdateLayerVTJ, DeleteLayerVTJ, GetVectorTilesThunk } from './mapCommonThunk';
import {
  SetShowSidebarAction,
  SetCurrentPanelAction,
  SetlayerSelectedAction,
  SetCurrentToolAction,
  ResetDrawSourceAction,
  ResetUpdateEntitiesAction,
  CreateFeatureAction,
  EditFeatureAction,
  DeleteAddedOrEditedFeatureAction,
  DeleteFeatureAction,
  SetDrawModeAction,
  CopyActiveFeatureAction,
  PasteActiveFeatureAction,
  CutActiveFeatureAction,
  GetGeoJsonForLayerSuccessAction,
  SetLayerUpdateAction,
  UpdateEntityStartAction,
  UpdateEntitySuccessAction,
  SetAutoUpdateAction,
  SetHighlightedLayerAction,
  SetCurrentLayerAction,
  SetSiteIdAction,
  UpdateFeatureAction,
} from './mapEditorActions';

export const GetGeoJsonForLayer = (layerName: string, fileVersion: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = `${API_URL}/tiles/${fileVersion}/getSingleGeoJson/${layerName}`;
    const { token } = getState().auth;

    const result = await getJson<any>(url, token);
    if (result.success) {
      if (result.data.features && result.data.features.length > 0) {
        // Parse WKT into curve form.
        result.data.features.forEach((feature: GeoJSON.Feature) => {
          if (feature.properties.wkt) {
            feature.properties.curve = wktToCurve(feature.properties.wkt, { normalize: true, circlesToPolygons: true });
            delete feature.properties.wkt;
          }
        });
        dispatch(GetGeoJsonForLayerSuccessAction(result.data));
      }
    }
    return result;
  };

export const SetCurrentPanel = (name: string) => (dispatch: Dispatch) => {
  dispatch(SetCurrentPanelAction(name));
  dispatch(SetShowSidebarAction(true));
};

export const SetCurrentTool = (name: string) => (dispatch: Dispatch, getState: () => RootState) => {
  const { layerSelected, siteId } = getState().map.editor.present;
  const { vectorTileJson } = getState().map.common;
  const orderId = getState().map.editor.present.orderSelected;
  const newSiteId = getState().order.fileVersions[orderId];
  const layerProps = vectorTileJson && vectorTileJson[orderId][newSiteId].vector_layers && vectorTileJson[orderId][newSiteId].vector_layers[0];

  // When we select a tool and there is no layerSelected, set it as the first layer on the list
  if (!layerSelected && layerProps) SetlayerSelected(layerProps.id)(dispatch);
  if (!siteId) dispatch(SetSiteIdAction(newSiteId));

  dispatch(SetCurrentToolAction(name));
};

export const SetLayerUpdate = (update: boolean) => (dispatch: Dispatch) => dispatch(SetLayerUpdateAction(update));

// Function that is called when we update any layer attribute including name, lineType, lineWidth and/or color
export const EditLayerAttribute = (layerId: number, siteId: string, data?: { name?:string, custom_name?: string, lineType?: string, lineWidth?: number, color?: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    const url = `${API_URL}/tiles/${siteId}/${layerId}/editLayer`;

    const result = await patchJson<any>(url, data, token);
    if (result.success) {
      const orderId = getState().map.editor.present.orderSelected;
      const updatedLayer = result.data;
      // eslint-disable-next-line no-undef
      const layer: IVectorLayer = {
        id: updatedLayer.name,
        custom_name: updatedLayer.custom_name,
        visible: true,
        color: updatedLayer.color,
        lineType: updatedLayer.line_type,
        lineWidth: updatedLayer.line_width,
        layerId: updatedLayer.id,
      };
      UpdateLayerVTJ(orderId, siteId, layer)(dispatch, getState);
      // dispatch(SetlayerSelectedAction(layer.id));
      GetVectorTilesThunk()(dispatch, getState);
      // datadog action - tracking user actions by passing custom attributes
      datadogRum.addAction('new_layer', {
        'layer.name': updatedLayer?.name,
      });
    }
  };

export const SetlayerSelected = (layer: string) => (dispatch: Dispatch) => dispatch(SetlayerSelectedAction(layer));
export const ResetDrawSource = () => (dispatch: Dispatch) => dispatch(ResetDrawSourceAction());
export const ResetUpdateEntities = () => (dispatch: Dispatch) => dispatch(ResetUpdateEntitiesAction());

export const Autosave = () => async (dispatch: Dispatch, getState: () => RootState) => {
  const { token } = getState().auth;
  const { siteId } = getState().map.editor.present;
  const { editEntities } = getState().map.editor.present;
  const { addEntities } = getState().map.editor.present;
  const { deleteEntities } = getState().map.editor.present;
  const featureCollection = { editEntities, addEntities, deleteEntities };

  if (
    addEntities.length > 0 ||
    editEntities.length > 0 ||
    deleteEntities.length > 0
  ) {
    const url = `${API_URL}/tiles/${siteId}/updateLayer`;

    dispatch(UpdateEntityStartAction());
    const result = await patchJson<any>(url, featureCollection, token);

    // When we get the geojson for a layer that is just updated, set the update variable in the store to true
    if (result.success) {
      if (addEntities.length > 0) {
        UpdateNewFeature(result.data.result, featureCollection.addEntities[0].id)(dispatch, getState);
      }
      dispatch(UpdateEntitySuccessAction());
      // Clear addEntities, editEntities and deleteEntities arrays once UpdateLayer API is called
      ResetUpdateEntities()(dispatch);
      // Clear Source GeoJSON as well, because we are going to get the updated GeoJSON for this layer
      // ResetDrawSource()(dispatch);
      SetAutoUpdate(true)(dispatch);
    }
  }
};

export const SetAutoUpdate = (update: boolean) => (dispatch: Dispatch) => dispatch(SetAutoUpdateAction(update));

export const UpdateNewFeature = (feature: GeoJSON.Feature, previousId:string) =>
  (dispatch: Dispatch, _getState: () => RootState) => {
    dispatch(UpdateFeatureAction({ feature, previousId }));
  };

export const UpdateFeature = (feature: GeoJSON.Feature, operation: string) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    // Depending on the update operation, store the GeoJSON feature in the corresponding array
    if (operation === 'edit') {
      let newFeature = feature;
      const curveProperty = feature.properties?.curve;
      if (curveProperty) {
        const curveWKT = curveToWkt(curveProperty, { autoPolygon: true, forceContinuous: true });
        newFeature = {
          ...feature,
          properties: {
            ...feature.properties,
            wkt: curveWKT,
          },
        };
      } else if (feature.properties.wkt) { // If the feature has a wkt property, we delete it so the backend create geom from using the default ST_GeomFromGeoJSON
        delete newFeature.properties.wkt;
      }
      // If we edit a feature we just created(before clicking save) update it in the addEntities array
      if (getState().map.editor.present.addEntities.findIndex((f: any) => f.id === feature.id) !== -1) {
        dispatch(CreateFeatureAction(newFeature));
        return;
      }
      dispatch(EditFeatureAction(newFeature));
    }
    if (operation === 'add') {
      // Different cases of 'adding' a feature-
      // - New linestring feature(line or polygon) added
      //      - can be added to on a existing layer
      //      - can be added to a new layer
      // - New linestring feature is extended but not closed
      // - New linestring feature is extended and closed
      // - Exisiting linestring feature is extended but not closed
      // - Exisiting linestring feature is extended and closed
      const { currentTool } = getState().map.editor.present;
      const { layerSelected } = getState().map.editor.present;
      const { vectorTileJson } = getState().map.common;
      const { siteId } = getState().map.editor.present;
      const orderId = getState().map.editor.present.orderSelected;
      const newSiteId = getState().order.fileVersions[orderId];

      // If there is no layer selected- the newly added feature will have the properties of the first layer in the list
      let layerProps = vectorTileJson[orderId][newSiteId].vector_layers[0];

      // If a layer is selected- get the properties of the layer from the vectorTileJson object
      if (layerSelected) {
        layerProps = vectorTileJson[orderId][newSiteId].vector_layers.find((layer) => layer.id === layerSelected);
      }

      // If no layer is selected or there is no siteId in the editor state, set them
      if (!layerSelected) SetlayerSelected(layerProps.id)(dispatch);
      if (!siteId) dispatch(SetSiteIdAction(newSiteId));

      let polygonType = false;
      const coords = feature.geometry.coordinates;
      const featureInAddEntities = getState().map.editor.present.addEntities.findIndex((f: any) => f.id === feature.id) !== -1;
      const featureInFeatureCollection = getState().map.editor.present.featureCollection.features.findIndex((f: any) => f.id === feature.id) !== -1;

      // Check if the feature is a Polygon by checking if the first and last coordinates are the same
      if ((currentTool === 'polyline' || currentTool === 'pointer' || currentTool === 'arc') && (coords[0][0] === coords[coords.length - 1][0] && coords[0][1] === coords[coords.length - 1][1])) {
        polygonType = true;
      }

      let curveProperty = feature.properties.curve;
      if (polygonType && feature.properties.curve) {
        curveProperty = ['curvepolygon', curveProperty];
      }

      const newFeature: GeoJSON.Feature<any> = {
        id: feature.id,
        type: 'Feature',
        geometry: {
          type: polygonType ? 'Polygon' : feature.geometry.type,
          coordinates: polygonType ? [[...feature.geometry.coordinates]] : [...feature.geometry.coordinates],
        },
        properties: {
          color: layerProps.color,
          line_type: layerProps.lineType,
          line_width: layerProps.lineWidth,
          name: layerProps.id,
          layer_id: layerProps.layerId,
          origin_epsg: layerProps.originEpsg,
          srid: layerProps.srid,
        },
      };
      if (curveProperty) {
        const curveWKT = curveToWkt(curveProperty, { autoPolygon: true, forceContinuous: true });
        newFeature.properties.wkt = curveWKT;
      }

      if (!featureInAddEntities && featureInFeatureCollection) {
        dispatch(EditFeatureAction(newFeature));
      } else {
        dispatch(CreateFeatureAction(newFeature));
      }
      Autosave()(dispatch, getState);
    }
    if (operation === 'delete') {
      // If we delete a feature we just created(before clicking save) remove it from the addEntities array
      if (getState().map.editor.present.addEntities.findIndex((f: any) => f.id === feature.id) !== -1) {
        dispatch(DeleteAddedOrEditedFeatureAction(feature));
        return;
      }
      // If we delete a feature we edited remove it editEntities array
      if (getState().map.editor.present.editEntities.findIndex((f: any) => f.id === feature.id) !== -1) {
        dispatch(DeleteAddedOrEditedFeatureAction(feature));
        return;
      }
      // Do not add empty arc features to the deleteEntities array
      if (feature.geometry.type === 'LineString' && Object.keys(feature.properties).length === 0) {
        return;
      }
      dispatch(DeleteFeatureAction(feature));
      Autosave()(dispatch, getState);
    }
  };

export const SetDrawMode = (name: string, params?: { featureId: string }) => (dispatch: Dispatch) => dispatch(SetDrawModeAction({ name, params }));

export const CopyActiveFeature = (featureId: any) => async (dispatch: Dispatch, getState: () => RootState) => {
  const findCollection = getState().map.editor.present.featureCollection.features.find((f) => f.id === featureId);
  dispatch(CopyActiveFeatureAction(findCollection));
};

export const CutActiveFeature = (featureId: any) => (dispatch: Dispatch, getState: () => RootState) => {
  const findCollection = getState().map.editor.present.featureCollection.features.find((f) => f.id === featureId);
  dispatch(CutActiveFeatureAction(findCollection));
  dispatch(DeleteFeatureAction(findCollection));
};

export const PasteActiveFeature = () => (dispatch: Dispatch, getState: () => RootState) => {
  const entity = { ...getState().map.editor.present.cloneEntity };
  if (entity) { entity.id = GenerateGuid(); }
  dispatch(PasteActiveFeatureAction(entity));
  SetDrawMode('direct_select', { featureId: entity.id })(dispatch);
};

export const UndoDrawing = () =>
  (dispatch: Dispatch) => {
    dispatch(ActionCreators.undo());
  };

export const DeleteActiveFeature = (featureId: any) => (dispatch: Dispatch, getState: () => RootState) => {
  const findCollection = getState().map.editor.present.featureCollection.features.find((f) => f.id === featureId);
  UpdateFeature(findCollection, 'delete')(dispatch, getState);
};

export const SendPendoEvent = () => (dispatch: Dispatch, getState: () => RootState) => {
  const mode = getState().map.editor?.present?.currentTool;
  const { projectId } = getState().project;
  window?.pendo?.track('Modified Linework', { tool: mode, projectId });
};

export const SetHighlightedLayer = (id: string) => (dispatch: Dispatch) => dispatch(SetHighlightedLayerAction(id));

export const AddNewLayer = (siteId: string, layerAttributes: { name: string, color: string, lineType: string, lineWidth: number }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    const url = `${API_URL}/tiles/${siteId}/createNewLayer`;
    const result = await postJson<any>(url, layerAttributes, token);
    if (result.success) {
      const newLayer = result.data;
      const layer: IVectorLayer = {
        id: newLayer.name,
        visible: true,
        color: newLayer.color,
        lineType: newLayer.line_type,
        lineWidth: newLayer.line_width,
        layerId: newLayer.id,
      };
      const orderId = getState().map.editor.present.orderSelected;
      AddNewLayerToVTJ(orderId, siteId, layer)(dispatch, getState);
      SetlayerSelected(layer.id)(dispatch);
      GetVectorTilesThunk()(dispatch, getState);
    }
  };

export const DeleteLayer = (siteId: string, layerId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { token } = getState().auth;
    const url = `${API_URL}/tiles/${siteId}/${layerId}/deleteLayer`;

    // dispatch(GetGeoJsonForLayerAsync.request());
    const result = await deleteRequest(url, token);

    if (result.success) {
      const orderId = getState().map.editor.present.orderSelected;
      SetlayerSelected(null)(dispatch);
      dispatch(SetCurrentLayerAction(null));
      ResetDrawSource()(dispatch);
      DeleteLayerVTJ(orderId, siteId, Number(layerId))(dispatch, getState);
      GetVectorTilesThunk()(dispatch, getState);
    }
  };
