import { createAction } from '@reduxjs/toolkit';

export const SetSiteIdAction = createAction<string>('mapEditor/SetSiteIdAction');

export const SetOrderSelectedAction = createAction<string>('mapEditor/SetOrderSelectedAction');

export const SetShowSidebarAction = createAction<boolean>('mapEditor/SetShowSidebarAction');

export const SetCurrentPanelAction = createAction<string>('mapEditor/SetCurrentPanelAction');

export const SetCurrentLayerAction = createAction<string>('mapEditor/SetCurrentLayerAction');

export const SetlayerSelectedAction = createAction<string>('mapEditor/SetlayerSelectedAction');

export const SetCurrentToolAction = createAction<string>('mapEditor/SetCurrentToolAction');

export const SetEditorModeAction = createAction<boolean>('mapEditor/SetEditorModeAction');

export const ResetDrawSourceAction = createAction('mapEditor/ResetDrawSourceAction');
export const ResetUpdateEntitiesAction = createAction('mapEditor/ResetUpdateEntitiesAction');

export const CreateFeatureAction = createAction<GeoJSON.Feature>('mapEditor/CreateFeatureAction');
export const DeleteAddedOrEditedFeatureAction = createAction<GeoJSON.Feature>('mapEditor/DeleteAddedOrEditedFeatureAction');
export const DeleteFeatureAction = createAction<GeoJSON.Feature>('mapEditor/DeleteFeatureAction');

export const EditFeatureAction = createAction<GeoJSON.Feature>('mapEditor/EditFeatureAction');
export const UpdateFeatureAction = createAction<{ feature: any, previousId: string }>('mapEditor/UpdateFeatureAction');

export const SetDrawModeAction = createAction<{ name: string, params?: { featureId: string } }>('mapEditor/SetDrawModeAction');

export const CopyActiveFeatureAction = createAction<GeoJSON.Feature>('mapEditor/CopyActiveFeatureAction');
export const CutActiveFeatureAction = createAction<GeoJSON.Feature>('mapEditor/CutActiveFeatureAction');
export const PasteActiveFeatureAction = createAction<GeoJSON.Feature>('mapEditor/PasteActiveFeatureAction');

export const GetGeoJsonForLayerSuccessAction = createAction<any>('mapEditor/GetGeoJsonForLayerSuccessAction');

export const UpdateEntityStartAction = createAction('mapEditor/UpdateEntityStartAction');
export const UpdateEntitySuccessAction = createAction('mapEditor/UpdateEntitySuccessAction');
export const UpdateEntityFailureAction = createAction('mapEditor/UpdateEntityFailureAction');

export const SetLayerUpdateAction = createAction<boolean>('mapEditor/SetLayerUpdateAction');
export const SetHighlightedLayerAction = createAction<string>('mapEditor/SetHighlightedLayerAction');

export const SetAutoUpdateAction = createAction<boolean>('mapEditor/SetAutoUpdateAction');
