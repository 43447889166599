// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography } from '@mui/material';
import { WideLightTooltip } from 'Components/Tooltip';
import { Box, useTheme } from '@mui/system';
import { useAppSelector } from 'Hooks';
import expressAiIcon from 'Assets/express-ai.png';
import stylesDef from './styles';

interface RadioButtonLabelProps {
  bundle: IBaseBundle;
  bundleType: string;
  pricePerFoot: number;
  selectedBundle: IBaseBundle | null;
  setPricePerFoot: (arg0: number) => void;
}

const RadioButtonLabel = ({ bundle, bundleType, pricePerFoot, selectedBundle }: RadioButtonLabelProps) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { acreAdjustmentFactor, name, bundleId } = bundle;
  const { density } = useAppSelector(
    (state) => state.bundle,
  );
  const expressAIBundle = bundleId === 9;

  return (
    <>
      <Box sx={styles.buttonLabelContainer}>
        <Box sx={styles.bundleNameContainer}>
          <Typography variant="h4" sx={styles.bundleName}>{name === '2D Only - Express AI' ? '2D Only' : name}</Typography>
          {expressAIBundle && (
            <Box sx={styles.betaBanner}>
              BETA
            </Box>
          )}
          {name === '3D Feature Elevation' && (
            <WideLightTooltip
              placement="top"
              title={(
                <div>
                  This bundle is currently in beta. There might be adjustments made to turnaround time and add-on layers in the near future. All layers in this bundle are returned in 3D by default. Please select 2D + Topo if you don’t require feature elevation
                  Read more about the 3D Feature Elevation offering
                  <a href="https://awkb.scrollhelp.site/airworksknowledgebase/Feature-Elevation-Bundle" style={styles.anchorTag} target="_blank" rel="noreferrer"> here. </a>
                </div>
              )}
            >
              <Box sx={styles.betaBanner}>
                BETA
              </Box>
            </WideLightTooltip>
          )}
          {bundleId === 5 && (
            <Box sx={styles.betaBanner}>
              NEW
            </Box>
          )}
        </Box>
        {bundleId !== 5 && !expressAIBundle && bundleType !== 'utilities' && (
          <Typography variant="h6">
            {`1 credit = ${acreAdjustmentFactor.length === 1 || density === 'normal' ? acreAdjustmentFactor[0] : acreAdjustmentFactor[1]} ${name}`}
          </Typography>
        )}
         {bundleType === 'utilities' && selectedBundle?.name === bundle.name && (
          <Typography variant="h6">
            {`Unit Cost: $${pricePerFoot}/ft ${name}`}
          </Typography>
        )}
        {expressAIBundle && (
          <Box style={styles.expressAi}>
            <img src={expressAiIcon} alt="expressAI" />
            <Typography variant="h6" style={styles.expressAiText}>
              Express AI
            </Typography>
          </Box>
        )}
      </Box>
      {bundleId === 5 && (
        <Typography variant="body2" sx={styles.bundleInfoText}>
          Select this option if you only want specific layers, not all of the ones offered in a base package
        </Typography>
      )}
    </>
  );
};

export default RadioButtonLabel;
